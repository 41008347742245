import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'servicios',
    loadChildren: () => import('./views/services/services.module').then(m => m.ServicesModule)
  },
  {
    path: 'nuevo-servicio',
    loadChildren: () => import('./views/new-service/new-service.module').then(m => m.NewServiceModule)
  },
  {
    path: 'depuracion',
    loadChildren: () => import('./views/depuration/depuration.module').then(m => m.DepurationModule)
  },
  {
    path: 'depuracion-detalle/:id',
    loadChildren: () => import('./views/depuration-detail/depuration-detail.module').then(m => m.DepurationDetailModule)
  },
  {
    path: 'depurados',
    loadChildren: () => import('./views/depuration-finished/depuration-finished.module').then(m => m.DepurationFinishedModule)
  },
  {
    path: 'depurados-detalle/:id',
    loadChildren: () => import('./views/depuration-finished-detail/depuration-finished-detail.module').then(m => m.DepurationFinishedDetailModule)
  },
  {
    path: 'formatos-fmt/:id',
    loadChildren: () => import('./views/formatos-fmt/formatos-fmt.module').then(m => m.FormatosfmtModule)
  },
  {
    path: 'cargar-evidencia/:id',
    loadChildren: () => import('./views/cargar-evidencia/cargar-evidencia.module').then(m => m.CargarevidenciaModule)
  },
  {
    path: 'girar-imagen/:id',
    loadChildren: () => import('./views/girar-imagen/girar-imagen.module').then(m => m.GirarimagenModule)
  },
  {
    path: 'services-por-niveles',
    loadChildren: () => import('./views/services-por-niveles/services-por-niveles.module').then(m => m.ServicesPorNivelesModule)
  },
   {
    path: 'clientes',
    loadChildren: () => import('./views/clientes/clientes.module').then(m => m.ClientesModule)
  },
   {
    path: 'subclientes',
    loadChildren: () => import('./views/subclientes/subclientes.module').then(m => m.SubclientesModule)
  },
  {
    path: 'new-cliente/:id',
    loadChildren: () => import('./views/new-cliente/new-cliente.module').then(m => m.NewClienteModule)
  },
   {
    path: 'new-subcliente/:id',
    loadChildren: () => import('./views/new-subcliente/new-subcliente.module').then(m => m.NewSubclienteModule)
  },
   {
    path: 'sedes/:id',
    loadChildren: () => import('./views/sedes/sedes.module').then(m => m.SedesModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./views/usuarios/usuarios.module').then(m => m.UsuariosModule)
  },
  {
    path: 'new-usuario/:id',
    loadChildren: () => import('./views/new-usuario/new-usuario.module').then(m => m.NewUsuarioModule)
  },
  {
    path: 'cliente-padrehijo',
    loadChildren: () => import('./views/cliente-padrehijo/cliente-padrehijo.module').then(m => m.ClientePadrehijoModule)
  },
    {
    path: 'equipos',
    loadChildren: () => import('./views/equipos/equipos.module').then(m => m.EquiposModule)
  },
  {
    path: 'equiposfiltro',
    loadChildren: () => import('./views/equiposfiltro/equiposfiltro.module').then(m => m.EquiposfiltroModule)
  },
  {
    path: 'new-equipo/:id',
    loadChildren: () => import('./views/new-equipo/new-equipo.module').then(m => m.NewEquipoModule)
  },
  {
    path: 'ver-equipo/:id',
    loadChildren: () => import('./views/new-equipo-show/new-equipo-show.module').then(m => m.NewEquipoShowModule)
  },
  {
    path: 'clientes-formatos',
    loadChildren: () => import('./views/clientes-formatos/clientes-formatos.module').then(m => m.ClientesFormatosModule)
  },
  {
    path: 'tipo-marca',
    loadChildren: () => import('./views/tipo-marca/tipo-marca.module').then(m => m.TipoMarcaModule)
  },
  {
    path: 'calendario',
    loadChildren: () => import('./views/calendario/calendario.module').then(m => m.CalendarioModule)
  },
   {
    path: 'consulta-tecnico',
    loadChildren: () => import('./views/consulta-tecnico/consulta-tecnico.module').then(m => m.ConsultaTecnicoModule)
  },
   {
    path: 'orden-de-trabajo',
    loadChildren: () => import('./views/orden-de-trabajo/orden-de-trabajo.module').then(m => m.OrdenDeTrabajoModule)
  },
   {
    path: 'terceros',
    loadChildren: () => import('./views/terceros/terceros.module').then(m => m.TercerosModule)
  },
  {
    path: 'new-tercero/:id',
    loadChildren: () => import('./views/new-tercero/new-tercero.module').then(m => m.NewTerceroModule)
  },
  {
    path: 'mano-obra',
    loadChildren: () => import('./views/mano-obra/mano-obra.module').then(m => m.ManoObraModule)
  },
  {
    path: 'new-manodeobra/:id',
    loadChildren: () => import('./views/new-manodeobra/new-manodeobra.module').then(m => m.NewManodeobraModule)
  },
  {
    path: 'reporte-ordendetrabajo',
    loadChildren: () => import('./views/reporte-ordendetrabajo/reporte-ordendetrabajo.module').then(m => m.ReporteOrdendetrabajoModule)
  },
  {
    path: 'maps',
    loadChildren: () => import('./views/maps/maps.module').then(m => m.MapsModule)
  },
  {
    path: 'equipos-por-niveles',
    loadChildren: () => import('./views/equipos-por-niveles/equipos-por-niveles.module').then(m => m.EquiposPorNivelesModule)
  },
  {
    path: 'productos',
    loadChildren: () => import('./views/inv-productos/inv-productos.module').then(m => m.InvProductosModule)
  },
  {
    path: 'nuevo-producto/:id',
    loadChildren: () => import('./views/inv-new-producto/inv-new-producto.module').then(m => m.InvNewProductoModule)
  },
  {
    path: 'movimiento',
    loadChildren: () => import('./views/inv-movimiento/inv-movimiento.module').then(m => m.InvMovimientoModule)
  },
  {
    path: 'nuevo-movimiento/:id',
    loadChildren: () => import('./views/inv-new-movimiento/inv-new-movimiento.module').then(m => m.InvNewMovimientoModule)
  },
  { 
    path: 'categoria',
    loadChildren: () => import('./views/inv-concepto/inv-concepto.module').then(m => m.InvConceptoModule)
  },
  { 
    path: 'rpt-saldo-inventario',
    loadChildren: () => import('./views/inv-rpt-saldo-inventario/inv-rpt-saldo-inventario.module').then(m => m.InvRptSaldoInventarioModule)
  },
   { 
    path: 'rpt-kardex',
    loadChildren: () => import('./views/inv-rpt-kardex/inv-rpt-kardex.module').then(m => m.InvRptKardexModule)
  },
  { 
    path: 'rpt-garantia',
    loadChildren: () => import('./views/inv-rpt-garantia/inv-rpt-garantia.module').then(m => m.InvRptGarantiaModule)
  },
  { 
    path: 'conceptos-her',
    loadChildren: () => import('./views/her-concepto/her-concepto.module').then(m => m.HerConceptoModule)
  },
  { 
    path: 'herramientas',
    loadChildren: () => import('./views/her-herramientas/her-herramientas.module').then(m => m.HerHerramientasModule)
  },
  {
    path: 'movimiento-her',
    loadChildren: () => import('./views/her-movimiento/her-movimiento.module').then(m => m.HerMovimientoModule)
  },
  {
    path: 'nuevo-movimiento-her/:id',
    loadChildren: () => import('./views/her-new-movimiento/her-new-movimiento.module').then(m => m.HerNewMovimientoModule)
  },
  { 
    path: 'rpt-kardex-her',
    loadChildren: () => import('./views/her-rpt-kardex/her-rpt-kardex.module').then(m => m.HerRptKardexModule)
  },
  { 
    path: 'rpt-garantia-her',
    loadChildren: () => import('./views/her-rpt-garantia/her-rpt-garantia.module').then(m => m.HerRptGarantiaModule)
  },
  { 
    path: 'rpt-saldo-inventario-her',
    loadChildren: () => import('./views/her-rpt-saldo-inventario/her-rpt-saldo-inventario.module').then(m => m.HerRptSaldoInventarioModule)
  },
  { 
    path: 'rpt-prestadas-her',
    loadChildren: () => import('./views/her-rpt-prestadas/her-rpt-prestadas.module').then(m => m.HerRptPrestadasModule)
  },
  {
    path: 'anticipos',
    loadChildren: () => import('./views/ant-anticipo/ant-anticipo.module').then(m => m.AntAnticipoModule)
  },
  {
    path: 'nuevo-anticipo/:id',
    loadChildren: () => import('./views/ant-new-anticipo/ant-new-anticipo.module').then(m => m.AntNewAnticipoModule)
  },
  { 
    path: 'anticipo-aprobacion',
    loadChildren: () => import('./views/ant-lista-anticipo-aprobacion/ant-lista-anticipo-aprobacion.module').then(m => m.AntListaAnticipoAprobacionModule)
  },
  { 
    path: 'anticipos-aprobado',
    loadChildren: () => import('./views/ant-anticipo-aprobados/ant-anticipo-aprobados.module').then(m => m.AntAnticipoAprobadosModule)
  },
  {
    path: 'legalizacion',
    loadChildren: () => import('./views/ant-legalizacion/ant-legalizacion.module').then(m => m.AntLegalizacionModule)
  },
  {
    path: 'nueva-legalizacion/:id',
    loadChildren: () => import('./views/ant-new-legalizacion/ant-new-legalizacion.module').then(m => m.AntNewLegalizacionModule)
  },
  { 
    path: 'concepto-ant',
    loadChildren: () => import('./views/ant-concepto/ant-concepto.module').then(m => m.AntConceptoModule)
  },
  { 
    path: 'rpt-anticipos',
    loadChildren: () => import('./views/ant-rpt-anticipos/ant-rpt-anticipos.module').then(m => m.AntRptAnticiposModule)
  },
  { 
    path: 'home',
    loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
  },
  { 
    path: 'reportelistaequiposservicio',
    loadChildren: () => import('./views/reportelistaequiposservicio/reportelistaequiposservicio.module').then(m => m.ReportelistaequiposservicioModule)
  },
   { 
    path: 'servicios-depurados',
    loadChildren: () => import('./views/servicios-depurados-cosulta/servicios-depurados-cosulta.module').then(m => m.ServiciosDepuradosCosultaModule)
  },
  { 
    path: 'solicitud-servicios',
    loadChildren: () => import('./views/solicitud-servicios/solicitud-servicios.module').then(m => m.SolicitudServiciosModule)
  },
  {
    path: 'nueva-solicitud-servicio',
    loadChildren: () => import('./views/new-solicitud-service/new-solicitud-service.module').then(m => m.NewSolicitudServiceModule)
  },
  {
    path: 'reporte-solicitud-servicios',
    loadChildren: () => import('./views/reporte-solicitud-servicios/reporte-solicitud-servicios.module').then(m => m.ReporteSolicitudServiciosModule)
  },
  {
    path: 'rpt-stock-sugerido',
    loadChildren: () => import('./views/reporte-stock-sugerido/reporte-stock-sugerido.module').then(m => m.ReporteStockSugeridoModule)
  },
  {
    path: 'calendario-tecnico',
    loadChildren: () => import('./views/calendario-tecnico/calendario-tecnico.module').then(m => m.CalendarioTecnicoModule)
  },
  {
    path: 'reportar-ubicacion',
    loadChildren: () => import('./views/reporte-tecnico-evidencia-ubicacion/reporte-tecnico-evidencia-ubicacion.module').then(m => m.ReporteTecnicoEvidenciaUbicacionModule)
  },
  {
    path: 'cancelados',
    loadChildren: () => import('./views/services-cancelled/services-cancelled.module').then(m => m.ServicesCancelledModule)
  },
   {
    path: 'rpt-sumatoria-tiempo-ocupacion',
    loadChildren: () => import('./views/rpt-sumatoria-tiempo-ocupacion/rpt-sumatoria-tiempo-ocupacion.module').then(m => m.RptSumatoriaTiempoOcupacionModule)
  },
  {
    path: 'requisicion',
    loadChildren: () => import('./views/inv-requisicion/inv-requisicion.module').then(m => m.InvRequisicionModule)
  },
  {
    path: 'nuevo-requisicion/:id',
    loadChildren: () => import('./views/inv-new-requisicion/inv-new-requisicion.module').then(m => m.InvNewRequisicionModule)
  },
  {
    path: 'rpt-requisicion',
    loadChildren: () => import('./views/inv-rpt-requisicion/inv-rpt-requisicion.module').then(m => m.InvRptRequisicionModule)
  },
  {
    path: 'modificar-tecnico-servicio',
    loadChildren: () => import('./views/modificar-tecnico-servicio/modificar-tecnico-servicio.module').then(m => m.ModificarTecnicoServicioModule)
  },
  {
    path: 'rpt-auditoria',
    loadChildren: () => import('./views/rpt-auditoria/rpt-auditoria.module').then(m => m.RptAuditoriaModule)
  },
  {
    path: 'rpt-consumo',
    loadChildren: () => import('./views/rpt-consumo/rpt-consumo.module').then(m => m.RptConsumoModule)
  },
  {
    path: 'permisos',
    loadChildren: () => import('./views/permisos/permisos.module').then(m => m.PermisosModule)
  },
  {
    path: 'encuesta',
    loadChildren: () => import('./views/encuesta/encuesta.module').then(m => m.EncuestaModule)
  },
  {
    path: 'encuesta_grafica',
    loadChildren: () => import('./views/encuesta_grafica/encuesta_grafica.module').then(m => m.EncuestaGraficaModule)
  },
  {
    path: 'her-requisicion',
    loadChildren: () => import('./views/her-requisicion/her-requisicion.module').then(m => m.HerRequisicionModule)
  },
  {
    path: 'nuevo-requisicion-her/:id',
    loadChildren: () => import('./views/her-new-requisicion/her-new-requisicion.module').then(m => m.HerNewRequisicionModule)
  },
  {  
    path: 'rpt-requisicion-her',
    loadChildren: () => import('./views/her-rpt-requisicion/her-rpt-requisicion.module').then(m => m.HerRptRequisicionModule)
  },
  {
    path: 'equiposlistafiltro/:id',
    loadChildren: () => import('./views/equiposlistafiltro/equiposlistafiltro.module').then(m => m.EquiposlistafiltroModule)
  },
  {  
    path: 'rpt-auditoria-ubicacion',
    loadChildren: () => import('./views/reporte-auditoria-ubicacion/reporte-auditoria-ubicacion.module').then(m => m.ReporteAuditoriaUbicacionModule)
  },
  {  
    path: 'rpt-comentario-equipo',
    loadChildren: () => import('./views/reporte-comentario-equipo/reporte-comentario-equipo.module').then(m => m.ReporteComentarioEquipoModule)
  },
  {
    path: 'indicadores',
    loadChildren: () => import('./views/indicators/indicators.module').then(m => m.IndicatorsModule)
  },
  {
    path: '**',
    redirectTo: 'login'
  },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
