import { Injectable } from '@angular/core';
import { stringify } from 'querystring';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsuarioApiService {

  urlEndPoint: string = environment.apiEndPoint;

  private readonly headerConfig = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    })
  };

  constructor(private http: HttpClient) {

  }



  private POST(nameServices: string, params: any) {
    return <any>this.http.post(this.urlEndPoint + nameServices, params)
  }

  private PUT(nameServices: string, id: any, params: any) {
    return <any>this.http.put(this.urlEndPoint + nameServices + '/' + id, params)
  }

  private GET(nameService: string, params: any = null) {
    return this.http.get<any>(`${this.urlEndPoint}${nameService}`, {
      headers: this.headerConfig.headers,
      params: params
    })
  }

  postServicio(param: any) {

    //  console.log(param);
    if (param) {
      return this.POST('usuarios', param);
    }
  }



  putServicio(id: any, param: any) {
    if (param) {
      return this.PUT('usuarios', id, param);
    }
    //return <any>this.http.put(this.urlEndPoint + 'empresa/'+ id,param)
  }

  /*putPassword(id: any, param: any){
    console.log(id, param);
      if (param){
        return this.PUT('usuarios',id,param);
      }
    }*/

  postCambiarPassword(cedula: number, password: string, imagen: any) {

    if (cedula) {
      return this.POST('cambiarpassword', { cedula, password, imagen })
    }
  }

  postFotoPerfil(cedula: number, imagen: string) {
    //console.log(cedula, imagen);
    if (cedula) {
      return this.POST('preferencia', { cedula, imagen })
    }
  }



  getUsuarios(param: number = null) {

    if (param) {
      return <any>this.http.get(this.urlEndPoint + 'usuarios/' + param);
    } else {
      return <any>this.http.get(this.urlEndPoint + 'usuarios');
    }
  }

  getListUserMin() {
    return this.GET('getListUserMin')
  }

  getUsuario(id: string) {
    return this.POST('getUsuario', { id })
  }

  getPermisos(id: number) {
    return this.POST('getPermisos', { id })
  }

  getPermisosUsuarios(id: number) {
    return this.POST('getPermisosUsuarios', { id })
  }

  GuardarPermisosUsuario(param: any) {
    if (param) {
      return this.POST('GuardarPermisosUsuario', param);
    }
  }

  getPermisosEspeciales(id: number) {
    return this.POST('getPermisosEspeciales', { id })
  }

  GuardarPermisosEspeciales( idPermisoEspecial: number, estadoPermisoEspecial:string, idusuario:number ) {
      return this.POST('GuardarPermisosEspeciales', {idPermisoEspecial, estadoPermisoEspecial, idusuario});
  }

  
}
