import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CitasApiService } from './services/citas-api.service';
import { Observable, fromEvent, merge, timer } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('servicioModal', { static: false }) servicioModal: ModalDirective;

  @ViewChild('inputCTL', { static: false }) inputCTL: ElementRef;

  title = 'siimsas-administration';
  opened = false;
  swMenu = 0;
  Session: any;
  imgBase64: string = '';
  v_usuario: any;
  usuarioLogin: string = '';
  rutaImagen = "";

  listaNotificacion: any[] = [];
  controlB = "0";
  filtroServicio: any;
  sw = 0;

  id_se_ = ""; ticket_ = ""; descripcion_ = ""; sitio_llegada_ = ""; fechahora_ini_ = "";
  fechahora_fin_ = ""; cliente_ = ""; subcliente_ = ""; sede_ = ""; estado_ = ""
  tecnicos_ = ""; equipos_ = ""; evidencias_ = ""; formatos_ = ""; pdf_ = ""; Encuesta_ = "";
  cantAnticipos_ = ""; montoTotal_ = ""; detalleAnticipo_ = "";

  constructor(private router: Router,
    private citasApiService: CitasApiService) {

  }

  ngOnInit() {
    this.getNotificacion();
    this.notificacionWeb();

    this.filtroServicio = "";
    this.controlB = "0";
    sessionStorage.setItem("controlB", "0");
    // esta opcion es para el control b
    this.bindKeypressEvent().subscribe(($event: KeyboardEvent) => this.onKeyPress($event));
  }

  // isExpanding = false;
  toggleSideBar() {
    this.opened = !this.opened;
  }

  ngAfterContentChecked() {
    this.Session = sessionStorage.getItem("inicioSession");
    if (this.Session == "ACTIVA") {
      this.swMenu = 1;
      this.v_usuario = JSON.parse(sessionStorage.getItem("user"));

      if (this.v_usuario != null) {
        if (this.v_usuario.imagen == "images/usuarios/imagen.jpg" || this.v_usuario.imagen == "images/usuarios/0000.jpg") {
          this.rutaImagen = "assets/imagen.jpg"
          this.usuarioLogin = this.v_usuario.nombres;
        } else {
          this.imgBase64 = this.v_usuario.imagen;
          this.usuarioLogin = this.v_usuario.nombres;
        }
      }
    } else {
      this.swMenu = 0;
    }

  }


  onClickSalir() {
    if (confirm("Estás seguro de que deseas salir de la Siimsas?")) {
      localStorage.clear();
      sessionStorage.clear();
      this.swMenu = 0;

      this.router.navigate(['login'])
      // window.location.reload(); 
    }
  }



  onClickCerrarNotificacion() {
    this.listaNotificacion = [];
  }

  notificacionWeb() {
    setInterval(() => {
      this.getNotificacion();
    }, 3600000); //60 minutos 



    setInterval(() => {
      this.controlB = sessionStorage.getItem("controlB");

      if (this.controlB == "1" && this.sw == 0) {
        this.sw = 1;
        setTimeout(() => {
          this.inputCTL.nativeElement.focus();
        }, 0);
      }

    }, 300); //1 minutos 

  }

  getNotificacion() {

    //  let Fechainicio=   moment().format('YYYY-MM-DD HH:mm:ss');
    //  let FechaFin= String(moment(Fechainicio).add(2, 'days').format('YYYY-MM-DD HH:mm:ss'));
    let Fechainicio = moment().format('YYYY-MM-DD');
    let FechaFin = String(moment(Fechainicio).add(2, 'days').format('YYYY-MM-DD'));
    this.listaNotificacion = [];

    this.citasApiService.getNotificacionDeServicios(Fechainicio, FechaFin).subscribe(response => {
      if (response.status == 200) {
        this.listaNotificacion = response.data;
      }
    });

  }

  onClickDashboard() {
    this.router.navigate(['home']);
  }


  onClickVerServicio() {
    let idServicio;
    localStorage.setItem('idService', this.id_se_);
    idServicio = localStorage.getItem('idService');
    if (this.estado_ == "CONFIRMADO") {
      this.servicioModal.hide();
      window.open('/depurados-detalle/' + idServicio, '_blank');
      return;
      // this.router.navigate(['/depurados-detalle', idServicio] );


    } else if (this.estado_ == "FINALIZADO") {
      this.servicioModal.hide();
      window.open('/depuracion-detalle/' + idServicio, '_blank');
      return;
      // this.router.navigate(['/depuracion-detalle',  idServicio ]);


    } else {
      this.servicioModal.hide();
      window.open('/nuevo-servicio/' , '_blank');
      return;

      // this.router.navigate(['nuevo-servicio']); 
    }
  }


  // onClickDepurateService(service: any) {
  //   window.open('/depuracion-detalle/'+service.id_se, '_blank');
  //   return;
  // }

  // esta opcion es para el control b
  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 25) {
      this.filtroServicio = "";
      sessionStorage.setItem("controlB", "1");
    }
  }

  // esta opcion es para el control b
  private bindKeypressEvent(): Observable<KeyboardEvent> {
    const eventsType$ = [
      fromEvent(window, 'keypress'),
      fromEvent(window, 'keydown')
    ];
    // we merge all kind of event as one observable.
    return merge(...eventsType$).pipe(      // We prevent multiple next by wait 10ms before to next value.
      debounce(() => timer(10)),      // We map answer to KeyboardEvent, typescript strong typing...
      map(state => (state as KeyboardEvent))
    );
  }

  onClickCerrarControlB() {
    sessionStorage.setItem("controlB", "0");
    this.sw = 0;
  }



  onClickBuscarServicio() {
    let idSer = String(this.filtroServicio)
    if (this.filtroServicio != "") {
      this.getInformacionGeneralDelServicio();
    }
  }

  getInformacionGeneralDelServicio() {
    let idSer = String(this.filtroServicio)
    this.citasApiService.getInformacionGeneralDelServicio(idSer).subscribe(response => {
      if (response.status == 200) {
        let lista = response.data[0];
        let listaTecnicos = response.data[1];
        let listaEquipos = response.data[2];
        let listaEvidencias = response.data[3];
        let listaEncuesta = response.data[4];
        let listaAnticipo = response.data[5];

        this.id_se_ = lista[0].id_se;
        this.ticket_ = lista[0].ticket;
        this.descripcion_ = lista[0].descripcion;
        this.sitio_llegada_ = lista[0].sitio_llegada;
        this.fechahora_ini_ = lista[0].fechahora_ini;
        this.fechahora_fin_ = lista[0].fechahora_fin;
        this.cliente_ = lista[0].cliente;
        this.subcliente_ = lista[0].subcliente;
        this.sede_ = lista[0].sede;
        this.estado_ = lista[0].estado;

        this.tecnicos_ = listaTecnicos[0].tecnicos;
        this.equipos_ = listaEquipos[0].equipos;

        this.evidencias_ = '0/IMG';
        this.formatos_ = '0/FTM';
        this.pdf_ = '0/PDF';

        for (let i = 0; i < listaEvidencias.length; i++) {
          let cant = listaEvidencias[0].cant;
          let tipo = listaEvidencias[0].tipo;

          if (tipo == "IMG") { this.evidencias_ = cant + '/' + tipo; }
          if (tipo == "FMT") { this.formatos_ = cant + '/' + tipo; }
          if (tipo == "PDF") { this.pdf_ = cant + '/' + tipo; }
        }

        this.Encuesta_ = listaEncuesta[0].cantEncuesta + '/Encuesta';

        this.cantAnticipos_ = listaAnticipo[0].cantAnticipos;
        this.montoTotal_ = listaAnticipo[0].montoTotal;
        this.detalleAnticipo_ = listaAnticipo[0].detalle;



        this.servicioModal.show();
      }
    });

  }


}


