import { Component, OnInit,ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UsuarioApiService } from 'src/app/services/usuario-api.service';
import { CitasApiService } from 'src/app/services/citas-api.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-siderbar',
  templateUrl: './siderbar.component.html',
  styleUrls: ['./siderbar.component.css']
})
export class SiderbarComponent implements OnInit {
  @ViewChild('servicioModal', { static: false }) servicioModal: ModalDirective;

  opened = false;
  public Session: any;
  v_usuario: any;
  idperfil: number;
  sw = 0;
  swAdministracion = 0;
  swServicio = 0;
  swManteniminto = 0;
  swInventario = 0;

  listaPermisos: any[] = [];
  listaAdministracion: any[] = [];
  listaservicio: any[] = [];
  listaMantenimiento: any[] = [];
  listaInventario: any[] = [];
  listaHerramienta: any[] = [];
  listaTesoreria: any[] = [];
  listaConsulta: any[] = [];

  constructor(private router: Router,
    private usuarioApiService: UsuarioApiService,
    private toast: ToastrService,
    private citasApiService: CitasApiService) {
    //esto controla el recargo de el menu lateral
    // localStorage.setItem("inicioSession", "INACTIVA")
  }

  ngOnInit() {
    this.v_usuario = JSON.parse(sessionStorage.getItem("user"));
    this.idperfil = this.v_usuario.id_perfil;
    this.getPermisos();
  }

  ngAfterContentChecked() {

  }


  toggleSidebar() {

    this.opened = !this.opened;
  }

  onClickSalir() {
    localStorage.setItem("inicioSession", "INACTIVA")
    this.router.navigate(['/login']);
  }

  /*********************METODOS*****************************/
  getPermisos() {
    this.usuarioApiService.getPermisosUsuarios(this.idperfil).subscribe(response => {
      if (response.status == 200) {
        let lista = response.data;

        this.listaAdministracion = lista.filter(item => item.modulo == "ADMINISTRACION");
        this.listaservicio = lista.filter(item => item.modulo == "SERVICIOS");
        this.listaMantenimiento = lista.filter(item => item.modulo == "MANTENIMIENTO");
        this.listaInventario = lista.filter(item => item.modulo == "INVENTARIO");
        this.listaHerramienta = lista.filter(item => item.modulo == "HERRAMIENTAS");
        this.listaTesoreria = lista.filter(item => item.modulo == "TESORERIA");
        this.listaConsulta = lista.filter(item => item.modulo == "CONSULTA");
      } else {
        this.toast.error(response.message, "Permisos");
      }
    })
  }







}
