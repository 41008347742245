import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CitasApiService {

  urlEndPoint: string = environment.apiEndPoint;

  private readonly headerConfig = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    })
  };

  constructor(private http: HttpClient) {

  }



  private POST(nameServices: string, params: any) {
    return <any>this.http.post(this.urlEndPoint + nameServices, params)
  }

  private PUT(nameServices: string, id: any, params: any) {
    return <any>this.http.put(this.urlEndPoint + nameServices + '/' + id, params)
  }

  private GET(nameService: string, params: any = null) {
    return this.http.get<any>(`${this.urlEndPoint}${nameService}`, {
      headers: this.headerConfig.headers,
      params: params
    })
  }


  GuardarCitaCalendario(param: any) {
    if (param) {
      return this.POST('GuardarCitaCalendario', param);
    }
  }

  ActualizarCitaCalendario(param: any) {
    if (param) {
      return this.POST('ActualizarCitaCalendario', param);
    }
  }

  getCitasPendientes(yearActal: string = null) {
    return this.POST('getCitasPendientes', { yearActal })
  }

  getUnaCitasPendientes(id: string = null) {
    return this.POST('getUnaCitasPendientes', { id })
  }

  getUnaCitasPendientesMin(id: string = null) {
    return this.POST('getUnaCitasPendientesMin', { id })
  }
  getTipoCitas(id: string = null) {
    return this.POST('getTipoCitas', { id })
  }


  EliminarCita(id: number) {
    return this.POST('EliminarCita', { id })
  }

  EliminarRecurso(id: number) {
    return this.POST('EliminarRecurso', { id })
  }

  EliminarEquipos(id: number) {
    return this.POST('EliminarEquipos', { id })
  }

  getValidarRecursos(tipo: string, Fechainicio: string, FechaFin: string, idRecurso: string, idTecnico: string, idCita: number) {
    return this.POST('getValidarRecursos', { tipo, Fechainicio, FechaFin, idRecurso, idTecnico, idCita })
  }

  getOrdenesDeTrabajo(id: number) {
    return this.POST('getOrdenesDeTrabajo', { id })
  }

  getComentarios(id: number) {
    return this.POST('getComentarios', { id })
  }

  GuardarComentarios(param: any) {
    if (param) {
      return this.POST('GuardarComentarios', param);
    }
  }

  GuardarComentariosWeb(param: any) {
    if (param) {
      return this.POST('GuardarComentariosWeb', param);
    }
  }

  ActualizarComentario(param: any) {
    if (param) {
      return this.POST('ActualizarComentario', param);
    }
  }

  getCitasTecnicosClientes(param: any) {
    return this.POST('getCitasTecnicosClientes', param)
  }


  getReporteServicios(param: any) {
    return this.POST('getReporteServicios', param)
  }


  getCitasPendientesTecnico(yearActal: string = null) {
    return this.POST('getCitasPendientesTecnico', { yearActal })
  }

  getCitasPendientesSubCliente(yearActal: string = null) {
    return this.POST('getCitasPendientesSubCliente', { yearActal })
  }


  getNotificacionDeServicios(Fechainicio: string, FechaFin: string) {
    return this.POST('getNotificacionDeServicios', { Fechainicio, FechaFin })
  }


  getListaEquiposComentario(idCliente: string, idSubcliente: string, idSede: string) {
    return this.POST('getListaEquiposComentario', { idCliente, idSubcliente, idSede })
  }


  getTecnicosDisponble(id: number) {
    return this.POST('getTecnicosDisponble', { id })
  }

  getUnaCitasTecnicos(idcita: string, idservicio: string) {
    return this.POST('getUnaCitasTecnicos', { idcita, idservicio })
  }

  ModificarHorarioTecnico(param: any) {
    if (param) {
      return this.POST('Modificar_HorarioTecnico', param);
    }
  }

  getInformacionGeneralDelServicio(idServicio: string) {
    return this.POST('getInformacionGeneralDelServicio', { idServicio })
  }

  getReporteComentarioEquipos(param: any) {
    return this.POST('getReporteComentarioEquipos', param)
  }
}
