import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SidebarModule } from 'ng-sidebar';
import { SiderbarComponent } from './components/siderbar/siderbar.component';


import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGrigPlugin from '@fullcalendar/timegrid';
import { ExcelService } from './services/excel-api.Service';                   
import { CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';


// import { PdfViewerModule } from 'ng2-pdf-viewer';
// import * as echarts from 'echarts/dist/echarts.js';
// import { NgxEchartsModule } from 'ngx-echarts';


FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin,
  timeGrigPlugin  
]);


@NgModule({
  declarations: [
    AppComponent,
    SiderbarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SidebarModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    FullCalendarModule,
    RouterModule,
    ModalModule.forRoot(),
    FormsModule,
    // PdfViewerModule
 
    // NgxEchartsModule.forRoot({
    //   echarts: () => import('echarts'),
    // }),
  ],
  providers: [ExcelService,CurrencyPipe],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
  
})
export class AppModule { }
